import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/fp/map'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography } from '@material-ui/core'

import FeatureGridItem from './FeatureGridItem'

const useStyles = makeStyles(
	theme => ({
		root: theme.mixins.gutters({
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			paddingBottom: theme.spacing(4),
			paddingTop: theme.spacing(4),
		}),
		feature: {
			margin: theme.spacing(-2, 0),
			padding: theme.spacing(4, 2),
		},
		features: {
			marginTop: theme.spacing(8),
		},
		heading: {
			fontSize: theme.typography.h4.fontSize,
			maxWidth: theme.spacing(8 * 10),
			padding: theme.spacing(8, 2, 0),

			[theme.breakpoints.up('md')]: {
				fontSize: theme.typography.h3.fontSize,
			},
		},
	}),
	{ name: 'FeatureGrid' }
)

const FeatureGrid = ({ features, heading, subHeading }) => {
	const classes = useStyles()

	return (
		<section className={classes.root}>
			{heading && (
				<Typography
					component={'h1'}
					className={classes.heading}
					align={'center'}
					gutterBottom
					variant={'h3'}
				>
					{heading}
				</Typography>
			)}
			{subHeading && (
				<Typography
					component={'h2'}
					align={'center'}
					gutterBottom
					variant={'h5'}
				>
					{subHeading}
				</Typography>
			)}
			<Container
				className={classes.features}
				component={'ul'}
				maxWidth={'lg'}
			>
				<Grid container>
					{map(feature => (
						<Grid item key={feature.title} xs={12} sm={6} md={4}>
							<div className={classes.feature}>
								<FeatureGridItem {...feature} />
							</div>
						</Grid>
					))(features)}
				</Grid>
			</Container>
		</section>
	)
}

FeatureGrid.propTypes = {
	features: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.string,
			icon: PropTypes.any,
			title: PropTypes.string,
		})
	),
	heading: PropTypes.string,
	subHeading: PropTypes.string,
}

export default FeatureGrid
