import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from 'mdi-material-ui'

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'flex-start',
			display: 'flex',
			padding: theme.spacing(0.5, 2),
		},
		icon: {
			marginRight: theme.spacing(1.5),
			marginTop: theme.spacing(0.5),
		},
		text: {
			fontWeight: theme.typography.fontWeightRegular,
		},
	}),
	{ name: 'FeatureListItem' }
)

const FeatureListItem = ({ className, title }) => {
	const classes = useStyles()

	return (
		<li className={`${classes.root} ${className}`}>
			<CheckCircleIcon className={classes.icon} color={'primary'} />
			<Typography className={classes.text} variant={'h6'}>
				{title}
			</Typography>
		</li>
	)
}

FeatureListItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
}

export default FeatureListItem
