import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			justifyContent: 'center',
			paddingBottom: theme.spacing(),
			paddingTop: theme.spacing(8),
		},
		content: {},
	}),
	{ name: 'Hero' }
)

const Hero = ({ children }) => {
	const classes = useStyles()

	return (
		<Container className={classes.root} component={'section'}>
			<div className={classes.content}>{children}</div>
		</Container>
	)
}

Hero.propTypes = {
	children: PropTypes.node,
}

export default Hero
