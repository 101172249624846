import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {
			minHeight: theme.spacing(8 * 8),
		},
		content: {
			padding: theme.spacing(4),

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(4, 6),
			},

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(8 * 2),
			},
		},
		image: {
			display: 'flex',
			left: '50%',
			height: '100%',
			objectFit: 'cover',
			position: 'absolute',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			width: '100%',
		},
		imageContainer: {
			overflow: 'hidden',
			position: 'relative',
		},
	}),
	{
		name: 'FeatureBox',
	}
)

const FeatureBox = ({
	action,
	body,
	imageUrl,
	heading,
	overline,
	reverse,
	subHeading,
}) => {
	const classes = useStyles()

	return (
		<Grid
			container
			component={'section'}
			className={classes.root}
			direction={reverse ? 'row-reverse' : 'row'}
		>
			<Grid item className={classes.imageContainer} xs={12} sm={6} md={6}>
				{imageUrl && (
					<img
						className={classes.image}
						src={imageUrl}
						alt={`${heading} feature image`}
					/>
				)}
			</Grid>
			<Grid item xs={12} sm={6} md={6}>
				<div className={classes.content}>
					{overline && (
						<Typography variant={'overline'}>{overline}</Typography>
					)}
					<Typography component={'h1'} gutterBottom variant={'h4'}>
						{heading}
					</Typography>
					{subHeading && (
						<Typography
							component={'h2'}
							gutterBottom
							variant={'h5'}
						>
							{subHeading}
						</Typography>
					)}
					<Typography paragraph>{body}</Typography>
					{action}
				</div>
			</Grid>
		</Grid>
	)
}

FeatureBox.propTypes = {
	action: PropTypes.node,
	body: PropTypes.string.isRequired,
	heading: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	overline: PropTypes.string,
	reverse: PropTypes.bool,
	subHeading: PropTypes.string,
}

export default FeatureBox
