import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/fp/map'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'

import FeatureListItem from './FeatureListItem'

const useStyles = makeStyles(
	theme => ({
		root: theme.mixins.gutters({
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		}),
		feature: {
			flexBasis: '100%',
			flexGrow: 1,

			[theme.breakpoints.up('sm')]: {
				flexBasis: '50%',
			},

			[theme.breakpoints.up('md')]: {
				flexBasis: '33%',
			},
		},
		features: theme.mixins.gutters({
			display: 'flex',
			flexWrap: 'wrap',
			margin: 0,
			paddingTop: theme.spacing(6),
			width: '100%',
		}),
	}),
	{
		name: 'FeatureBox',
	}
)
const FeatureList = ({ features, heading, subHeading }) => {
	const classes = useStyles()

	return (
		<section className={classes.root}>
			<Typography component={'h1'} gutterBottom variant={'h4'}>
				{heading}
			</Typography>
			{subHeading && (
				<Typography component={'h2'} gutterBottom variant={'h5'}>
					{subHeading}
				</Typography>
			)}
			<Container
				className={classes.features}
				component={'ul'}
				maxWidth={'lg'}
			>
				{map(feature => (
					<FeatureListItem
						className={classes.feature}
						key={feature.title}
						{...feature}
					/>
				))(features)}
			</Container>
		</section>
	)
}

FeatureList.propTypes = {
	features: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
		})
	),
	heading: PropTypes.string.isRequired,
	subHeading: PropTypes.string,
}

export default FeatureList
