import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {
			padding: theme.spacing(6, 4),
			textAlign: 'center',
		},
		content: {
			fontWeight: theme.typography.fontWeightMedium,
		},
		icon: {
			color: theme.palette.common.white,
			height: theme.spacing(3),
			width: theme.spacing(3),
		},
		iconWrapper: {
			alignItems: 'center',
			backgroundColor: theme.palette.secondary.main,
			borderRadius: theme.spacing(5),
			display: 'inline-flex',
			height: theme.spacing(10),
			justifyContent: 'center',
			width: theme.spacing(10),
		},
		title: {
			fontWeight: theme.typography.fontWeightMedium,
			marginTop: theme.spacing(2.5),
		},
	}),
	{ name: 'FeatureGridItem' }
)

const FeatureGridItem = ({ color, content, icon: FeatureIcon, title }) => {
	const classes = useStyles()

	const iconStyle = useRef({ backgroundColor: color })

	return (
		<div className={classes.root}>
			<div className={classes.iconWrapper} style={iconStyle.current}>
				<FeatureIcon className={classes.icon} />
			</div>
			<Typography
				className={classes.title}
				align={'center'}
				gutterBottom
				variant={'h5'}
			>
				{title}
			</Typography>
			{content && (
				<Typography
					className={classes.content}
					align={'center'}
					color={'textSecondary'}
					variant={'h6'}
				>
					{content}
				</Typography>
			)}
		</div>
	)
}

FeatureGridItem.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	content: PropTypes.string,
	icon: PropTypes.any,
	title: PropTypes.string,
}

export default FeatureGridItem
