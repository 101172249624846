import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {
			fontWeight: 700,
		},
	}),
	{ name: 'HeroTitle' }
)

const HeroTitle = ({ title }) => {
	const classes = useStyles()

	return (
		<Typography
			className={classes.root}
			align={'center'}
			gutterBottom
			variant={'h3'}
		>
			{title}
		</Typography>
	)
}

HeroTitle.propTypes = {
	title: PropTypes.string,
}

export default HeroTitle
