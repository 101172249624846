import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {},
	}),
	{ name: 'HeroSubtitle' }
)

const HeroSubtitle = ({ title }) => {
	const classes = useStyles()

	return (
		<Typography
			className={classes.root}
			align={'center'}
			gutterBottom
			variant={'h5'}
		>
			{title}
		</Typography>
	)
}

HeroSubtitle.propTypes = {
	title: PropTypes.string,
}

export default HeroSubtitle
