import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
	emphasize,
	getLuminance,
	makeStyles,
	useTheme,
} from '@material-ui/core/styles'

const useStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: -theme.spacing(12),
		},
		background: {},
		divider: {
			height: theme.spacing(12),
			width: '100%',
			zIndex: 1,

			'& path': {
				strokeWidth: 0,
				width: '100%',
			},
		},
	}),
	{ name: 'HeroSplash' }
)

const HeroSplash = ({ children, color = '#fff' }) => {
	const theme = useTheme()
	const classes = useStyles()

	const textColor = useMemo(() => {
		let luminance
		try {
			luminance = getLuminance(color)
		} catch (err) {
			luminance = 0
		}

		return luminance <= 0.5
			? emphasize(theme.palette.text.primary, 1)
			: theme.palette.text.primary
	}, [color])

	const backgroundStyle = useMemo(
		() => ({
			backgroundColor: color,
			color: textColor,
		}),
		[color]
	)
	const pathStyle = useMemo(
		() => ({
			fill: color,
		}),
		[color]
	)

	return (
		<div>
			<div className={classes.background} style={backgroundStyle}>
				{children}
			</div>
			<svg
				className={classes.divider}
				xmlns={'http://www.w3.org/2000/svg'}
				preserveAspectRatio={'none'}
				viewBox={'0 0 5000 400'}
			>
				<path d={'M0 0 L0 400 L5000 0 Z'} style={pathStyle} />
			</svg>
		</div>
	)
}

HeroSplash.propTypes = {
	children: PropTypes.any,
	color: PropTypes.string,
}

export default HeroSplash
