import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'center',
			display: 'flex',
			height: theme.spacing(8),
			justifyContent: 'space-between',
			position: 'sticky',
			top: 0,
			transition: theme.transitions.create(),
		},
		rootStuck: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[6],
		},
	}),
	{ name: 'Header' }
)

const Header = ({ children }) => {
	const [stuck, setStuck] = useState(false)
	const classes = useStyles()

	const handleScroll = event => {
		const scrollTop = event.target.scrollingElement.scrollTop
		if (scrollTop > 0) {
			setStuck(true)
		} else {
			setStuck(false)
		}
	}

	useEffect(() => {
		if (!window) return
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<Container
			className={cx(classes.root, { [classes.rootStuck]: stuck })}
			component={'nav'}
		>
			{children}
		</Container>
	)
}

Header.propTypes = {
	children: PropTypes.node,
}

export default Header
