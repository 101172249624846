import React, { useState } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/fp/map'

import { emphasize, makeStyles } from '@material-ui/core/styles'
import { Tab, Tabs, Typography } from '@material-ui/core'

const mapWithKey = map.convert({ cap: false })

const useTabPanelStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		width: '100%',
	},
	content: {
		width: '100%',
	},
}))

const TabPanel = ({ children, value, index, ...other }) => {
	const classes = useTabPanelStyles()
	return (
		<div
			className={classes.root}
			role={'tabpanel'}
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className={classes.content}>{children}</div>
			)}
		</div>
	)
}

const useTabStyles = makeStyles(theme => ({
	root: {
		backgroundColor: emphasize(theme.palette.background.paper, 0.08),
		borderRadius: 100,
		display: 'inline-flex',
		minHeight: theme.spacing(5),
	},
	flexContainer: {
		position: 'relative',
		padding: '0 3px',
		zIndex: 1,
	},
	indicator: {
		top: 3,
		bottom: 3,
		right: 3,
		height: 'auto',
		borderRadius: 100,
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
	},
}))

const useTabItemStyles = makeStyles(theme => ({
	root: {
		minHeight: 44,
		minWidth: 96,

		'&:hover': {
			opacity: 1,
		},

		[theme.breakpoints.up('md')]: {
			minWidth: 120,
		},
	},
	wrapper: {
		color: theme.palette.text.primary,
		textTransform: 'initial',
	},
}))

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			paddingTop: theme.spacing(8),
		},
		feature: {
			margin: theme.spacing(-2, 0),
			padding: theme.spacing(4, 2),
		},
		features: {
			marginTop: theme.spacing(8),
		},
		heading: {
			fontSize: theme.typography.h4.fontSize,
			marginBottom: theme.spacing(4),
			maxWidth: theme.spacing(8 * 10),
			padding: theme.spacing(0, 2),

			[theme.breakpoints.up('md')]: {
				fontSize: theme.typography.h3.fontSize,
			},
		},
		tabs: {},
		tabsWrapper: theme.mixins.gutters({
			marginBottom: theme.spacing(4),
		}),
	}),
	{ name: 'FeatureTabs' }
)

const FeatureTabs = ({ features, heading, subHeading }) => {
	const [tabIndex, setTabIndex] = useState(0)
	const tabsStyles = useTabStyles()
	const tabItemStyles = useTabItemStyles()
	const classes = useStyles()

	return (
		<section className={classes.root}>
			{heading && (
				<Typography
					component={'h1'}
					className={classes.heading}
					align={'center'}
					gutterBottom
					variant={'h3'}
				>
					{heading}
				</Typography>
			)}
			{subHeading && (
				<Typography
					component={'h2'}
					align={'center'}
					gutterBottom
					variant={'h5'}
				>
					{subHeading}
				</Typography>
			)}
			<div className={classes.tabsWrapper}>
				<Tabs
					className={classes.tabs}
					classes={tabsStyles}
					value={tabIndex}
					onChange={(e, index) => setTabIndex(index)}
				>
					{map(({ title }) => (
						<Tab
							classes={tabItemStyles}
							disableRipple
							label={title}
							key={title}
						/>
					))(features)}
				</Tabs>
			</div>

			{mapWithKey(({ content, title }, index) => (
				<TabPanel index={index} key={title} value={tabIndex}>
					{content}
				</TabPanel>
			))(features)}
		</section>
	)
}

FeatureTabs.propTypes = {
	features: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.object,
			title: PropTypes.string,
		})
	),
	heading: PropTypes.string,
	subHeading: PropTypes.string,
}

export default FeatureTabs
